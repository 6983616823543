import React from "react"
import styled, { keyframes } from "styled-components"
import globalVariables from "../globalVariables"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import Parser from "html-react-parser"
import { rubberBand } from "react-animations"

import Title, { TitleH2 } from "../elements/Title"
import IconLieu from "../../images/icons/lieubleu.svg"
import ButtonAnchor from "../elements/Anchor/ButtonAnchor"

const rubberBandAnimation = keyframes`${rubberBand}`

const Section = styled.section`
  position: relative;
  @media (min-width: ${globalVariables.minTablet}) {
    margin-bottom: 95px;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${props => (props.justify ? props.justify : "center")};
  align-items: ${props => (props.align ? props.align : null)};
  height: 100%;
  width: 100%;
  &.profil-row div:nth-child(1),
  &.profil-row div:nth-child(4),
  &.profil-row div:nth-child(5) {
    .profil-card {
      background: ${props => props.theme.greyLighter};
    }
  }
  @media (min-width: ${globalVariables.minTablet}) {
    &.profil-row > div {
      flex: 1 1 50%;
    }
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    &.profil-row > div {
      flex: 1 1 25%;
    }
    &.profil-row div:nth-child(1),
    &.profil-row div:nth-child(3),
    &.profil-row div:nth-child(6) {
      .profil-card {
        background: ${props => props.theme.greyLighter};
      }
    }
    &.profil-row div:nth-child(4),
    &.profil-row div:nth-child(5) {
      .profil-card {
        background: ${props => props.theme.white};
      }
    }
  }
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const ItemLeft = styled.div`
  @media (min-width: ${globalVariables.minTablet}) {
    flex: 0 0 63%;
    margin: 50px 0 0 0;
  }
`
const ItemRight = styled.div`
  @media (max-width: ${globalVariables.maxMobile}) {
    display: none;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    flex: 0 0 16%;
    margin: 50px 11px 0 25px;
  }
`

const Item = styled.div`
  flex: ${props => (props.flexMobile ? props.flexMobile : "1 1 100%")};
  min-height: ${props => (props.minHeight ? props.minHeight : null)};
  position: relative;
  & img:hover {
    animation: 1s ${rubberBandAnimation};
  }
  @media (max-width: ${globalVariables.maxMobile}) {
    order: ${props => (props.order ? props.order : null)};
    &.profil-title {
      margin-top: 21px;
      div {
        align-items: center;
      }
    }
  }
  @media (min-width: ${globalVariables.minTablet}) {
    flex: 1 1 auto;
    margin: ${props => (props.margin ? props.margin : null)};
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    flex: ${props => props.flex};
  }
  @media (min-width: ${globalVariables.bigDesktop}) {
    min-height: ${props =>
      props.minHeightBigScreen ? props.minHeightBigScreen : null};
  }
`

const Card = styled.div`
  background: ${props => props.theme[props.color]};
  box-shadow: ${props =>
    props.shadow ? "0 2px 10px 0 rgba(0, 0, 0, 0.08)" : null};
  padding: 40px 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.justify ? props.justify : "center")};
  align-items: ${props => (props.align ? props.align : "center")};
  border: ${props => (!props.noBorder ? "1px solid white" : null)};
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    padding: ${props => props.padding};
  }
`

const CardImage = styled(Card)`
  background: url(${props => props.imageUrl});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
`

const ContentJob = styled.div`
  text-align: justify;
  ul {
    padding-left: 2rem;
    margin-bottom: 2rem;
  }
  h2 {
    font-size: 22px;
  }
`

const Text = styled.p`
  color: ${props => (props.color ? props.theme[props.color] : null)};
  font-weight: ${props => (props.weight ? props.weight : null)};
  margin: ${props => (props.margin ? props.margin : null)};
  text-align: ${props => (props.align ? props.align : "center")};
  max-width: 225px;
`

const TextProfil = styled.p`
  max-width: 225px;
  margin: ${props => (props.margin ? props.margin : null)};
  text-align: center;
  b {
    color: ${props => props.theme.primary};
  }
`

const TextMedium = styled.p`
  font-weight: 500;
  margin: 0;
`

const TextInfo = styled.p`
  color: ${props => (props.color ? props.theme[props.color] : null)};
  text-transform: uppercase;
  font-weight: bold;
  margin: 0;
  text-align: center;
`

const Icon = styled.img`
  margin-right: 5px;
`

const WrapperButton = styled.div`
  text-align: center;
  margin: 20px 0 35px 0;
  a {
    width: 190px;
  }
`

const SocialList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
`

const SocialLink = styled.a`
  width: 48px;
  height: 48px;
  margin: 0 10px 30px;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: rotate(360deg);
  }
`
const LogoCard = styled.img`
  max-height: 80px;
`

const ItemHover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => (props.background ? props.background : null)};
  border: 1px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  p {
    color: ${props => (props.color ? props.theme[props.color] : null)};
    margin: 0;
    text-align: center;
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    background: transparent;
    p {
      opacity: 0;
    }
  }
`

const List = styled.ul`
  margin-left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const Element = styled.li`
  list-style: none;
  background: white;
  padding: 2px 5px;
  margin: 5px;
`
const RowLogo = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  & > div {
    flex: 1 1 50%;
  }
`
const LogoProfil = styled.img`
  padding: 10px;
  width: 80px;
`

const FicheDePostContent = ({
  title,
  jobTitle,
  jobText,
  teamLeader,
  imageTeamLeader,
  imageEquipe,
  imageBureaux,
  avantages,
  contract,
  city,
  experience,
  collaborateurs,
  age,
  profils,
  case5,
  case8,
  case12,
  case1,
  case2,
  case3,
  case4,
  case6,
  case7,
  case9,
  case10,
  case11,
  case13,
  case14,
  profilCase8,
}) => {
  const data = useStaticQuery(
    graphql`
      query {
        fbk: file(relativePath: { eq: "icons/fb-gris.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        lkd: file(relativePath: { eq: "icons/in-gris.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        twt: file(relativePath: { eq: "icons/twt-gris.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        analytics: file(relativePath: { eq: "fiche-de-poste/analytics.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 200) {
              src
            }
          }
        }
        tagManager: file(
          relativePath: { eq: "fiche-de-poste/tag-manager.png" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 200) {
              src
            }
          }
        }
        dataStudio: file(
          relativePath: { eq: "fiche-de-poste/google-data-studio-logo.png" }
        ) {
          childImageSharp {
            fluid(quality: 100) {
              src
            }
          }
        }
        googleAds: file(relativePath: { eq: "fiche-de-poste/google-ads.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              src
            }
          }
        }
        fbkAds: file(relativePath: { eq: "fiche-de-poste/fbk-ads.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              src
            }
          }
        }
      }
    `
  )

  return (
    <Section id="mission">
      <Row justify="flex-end">
        <ItemLeft>
          <Column>
            <Item flex="1 1 100%" margin="0 0 21px 0">
              <Card
                color="greyLighter"
                padding="45px 40px 25px 45px"
                justify="flex-start"
                align="flex-start"
              >
                <TitleH2 className="title1" color="primary">
                  {jobTitle}
                </TitleH2>
                <ContentJob>{Parser(jobText)}</ContentJob>
              </Card>
            </Item>
            <Row>
              <Item
                flex="1 1 25%"
                flexMobile="1 1 50%"
                minHeight="130px"
                order="1"
              >
                <Card color="primary" padding="25px 15px">
                  <Text weight="700" color="white" margin="0">
                    {case1.titre_case_1}
                  </Text>
                  {case1.texte_case_1 && (
                    <Text color="white" margin="10px 0 0 0">
                      {case1.texte_case_1}
                    </Text>
                  )}
                </Card>
              </Item>
              <Item
                flex="1 1 25%"
                flexMobile="1 1 50%"
                minHeight="130px"
                order="2"
              >
                <Card color="greyLighter" padding="25px 15px">
                  <Text weight="700" margin="0">
                    {case2.titre_case_2}
                  </Text>
                  {case2.texte_case_2 && (
                    <Text margin="10px 0 0 0">{case2.texte_case_2}</Text>
                  )}
                </Card>
              </Item>
              <Item
                flex="1 1 25%"
                flexMobile="1 1 50%"
                minHeight="130px"
                order="3"
              >
                <Card padding="25px 15px">
                  <Text weight="700" margin="0">
                    {case3.titre_case_3}
                  </Text>
                  {case3.texte_case_3 && (
                    <Text margin="10px 0 0 0">{case3.texte_case_3}</Text>
                  )}
                </Card>
              </Item>
              <Item
                flex="1 1 25%"
                flexMobile="1 1 50%"
                minHeight="130px"
                order="4"
              >
                <Card color="secondary" padding="25px 15px">
                  <Text weight="700" color="white" margin="0">
                    {case4.titre_case_4}
                  </Text>
                  {case4.texte_case_4 && (
                    <Text color="white" margin="10px 0 0 0">
                      {case4.texte_case_4}
                    </Text>
                  )}
                </Card>
              </Item>
            </Row>
            <Row>
              <Item flex="1 1 50%">
                <Row>
                  <Row>
                    <Item
                      flex="0 0 50%"
                      flexMobile="1 1 50%"
                      minHeight="130px"
                      order="2"
                    >
                      <Card padding="25px 15px">
                        <LogoCard src={case5} />
                      </Card>
                    </Item>
                    <Item
                      flex="0 0 50%"
                      flexMobile="1 1 50%"
                      minHeight="130px"
                      order="1"
                    >
                      <Card color="secondary" padding="25px 15px">
                        <Text weight="700" margin="0" color="white">
                          {case6.titre_case_6}
                        </Text>
                        {case6.texte_case_6 && (
                          <Text margin="10px 0 0 0" color="white">
                            {case6.texte_case_6}
                          </Text>
                        )}
                      </Card>
                    </Item>
                    <Item
                      flex="0 0 50%"
                      flexMobile="1 1 50%"
                      minHeight="130px"
                      order="4"
                    >
                      <Card color="primary" padding="25px 15px">
                        <Text weight="700" margin="0" color="white">
                          {case7.titre_case_7}
                        </Text>
                        {case7.texte_case_7 && (
                          <Text margin="10px 0 0 0" color="white">
                            {case7.texte_case_7}
                          </Text>
                        )}
                      </Card>
                    </Item>
                    <Item
                      flex="0 0 50%"
                      flexMobile="1 1 50%"
                      minHeight="130px"
                      order="3"
                    >
                      <Card padding="25px 15px" color="white">
                        <LogoCard src={case8} />
                      </Card>
                    </Item>
                  </Row>
                </Row>
              </Item>
              <Item flex="1 1 50%" minHeight="260px" minHeightBigScreen="330px">
                <CardImage imageUrl={imageEquipe}></CardImage>
                <ItemHover background="rgba(2, 230, 187, 0.3)" color="white">
                  <p>L'équipe</p>
                </ItemHover>
              </Item>
            </Row>
            <Row>
              <Item flex="1 1 25%" flexMobile="1 1 50%" order="1">
                <Row>
                  <Item
                    flex="1 1 100%"
                    minHeight="280px"
                    minHeightBigScreen="330px"
                  >
                    <CardImage imageUrl={imageTeamLeader}></CardImage>
                    <ItemHover background="rgba(255, 255, 255, 0.8)">
                      <Text>Rencontre</Text>
                      <Text color="primary" weight="700">
                        {teamLeader}
                      </Text>
                      <Text weight="700">Team Leader</Text>
                    </ItemHover>
                  </Item>
                  <Item flex="1 1 100%" minHeight="130px">
                    <Card color="greyLighter" padding="25px 15px">
                      <Text weight="700" margin="0">
                        {case9.titre_case_9}
                      </Text>
                      {case9.texte_case_9 && (
                        <Text margin="10px 0 0 0">{case9.texte_case_9}</Text>
                      )}
                    </Card>
                  </Item>
                </Row>
              </Item>
              <Item flex="1 1 50%" order="3">
                <Row>
                  <Item
                    flex="0 0 50%"
                    flexMobile="1 1 50%"
                    minHeight="130px"
                    order="2"
                  >
                    <Card color="primary" padding="25px 15px">
                      <Text weight="700" margin="0" color="white">
                        {case10.titre_case_10}
                      </Text>
                      {case10.texte_case_10 && (
                        <Text margin="10px 0 0 0" color="white">
                          {case10.texte_case_10}
                        </Text>
                      )}
                    </Card>
                  </Item>
                  <Item
                    flex="1 1 50%"
                    flexMobile="1 1 50%"
                    minHeight="130px"
                    order="3"
                  >
                    <Card color="greyLighter" padding="25px 15px">
                      <Text weight="700" margin="0">
                        {case11.titre_case_11}
                      </Text>
                      {case11.texte_case_11 && (
                        <Text margin="10px 0 0 0">{case11.texte_case_11}</Text>
                      )}
                    </Card>
                  </Item>
                  <Item flex="1 1 100%" minHeight="280px" order="1">
                    <CardImage imageUrl={imageBureaux}></CardImage>
                    <ItemHover
                      background="rgba(80, 11, 117, 0.5)"
                      color="white"
                    >
                      <p>Les bureaux</p>
                    </ItemHover>
                  </Item>
                </Row>
              </Item>
              <Item flex="1 1 25%" flexMobile="1 1 50%" order="2">
                <Row>
                  <Item flex="1 1 100%" minHeight="130px">
                    <Card color="primary" padding="25px 15px">
                      <Text weight="700" margin="0" color="white">
                        {case13.titre_case_13}
                      </Text>
                      {case13.texte_case_13 && (
                        <Text margin="10px 0 0 0" color="white">
                          {case13.texte_case_13}
                        </Text>
                      )}
                    </Card>
                  </Item>
                  <Item flex="1 1 100%" minHeight="130px">
                    <Card padding="25px 15px">
                      <LogoCard src={case12} />
                    </Card>
                  </Item>
                  <Item flex="1 1 100%" minHeight="130px">
                    <Card color="secondary" padding="25px 15px">
                      <Text weight="700" color="white" margin="0">
                        {case14.titre_case_14}
                      </Text>
                      {case14.texte_case_14 && (
                        <Text color="white" margin="10px 0 0 0">
                          {case14.texte_case_14}
                        </Text>
                      )}
                    </Card>
                  </Item>
                </Row>
              </Item>
            </Row>
            <Row>
              <Item flex="1 1 25%">
                <Card color="greyLighter">
                  <Title className="title1" color="primary" margin="0">
                    Les +
                  </Title>
                </Card>
              </Item>
              <Item flex="1 1 75%">
                <Card color="greyLighter" padding="24px 27px">
                  <List>
                    {avantages.map((item, index) => (
                      <Element key={index}>{item.texte}</Element>
                    ))}
                  </List>
                </Card>
              </Item>
            </Row>

            <Item
              flex="1 1 100%"
              margin="21px 0 0 0"
              className="profil-title"
              id="profil-recherche"
            >
              <Card color="primary" padding="30px 30px 27px" align="flex-start">
                <Title className="title1" color="white" margin="0">
                  Profil recherché
                </Title>
              </Card>
            </Item>
            <Row className="profil-row">
              {profils.map((item, index) => (
                <Item
                  flex="0 0 25%"
                  flexMobile="1 1 50%"
                  minHeight="215px"
                  key={index}
                >
                  <Card padding="20px" noBorder className="profil-card">
                    {item.profil.titre_bleu_1 && (
                      <TextProfil margin="0">
                        <b>{item.profil.titre_bleu_1}</b>
                      </TextProfil>
                    )}
                    {item.profil.texte && (
                      <TextProfil margin="0">{item.profil.texte}</TextProfil>
                    )}
                    {item.profil.texte_bleu_2 && (
                      <TextProfil margin="0">
                        <b>{item.profil.texte_bleu_2}</b>
                      </TextProfil>
                    )}
                    {item.profil.images && item.profil.images[0].image && (
                      <RowLogo>
                        {item.profil.images.map((image, index) => (
                          <Item key={index}>
                            <LogoProfil
                              src={
                                image.image.localFile.childImageSharp.fluid.src
                              }
                              alt="logo"
                            />
                          </Item>
                        ))}
                      </RowLogo>
                    )}
                  </Card>
                </Item>
              ))}

              <Item flex="0 0 25%" flexMobile="1 1 50%" minHeight="215px">
                <Card color="greyLighter" padding="20px" noBorder>
                  {profilCase8.map((item, index) => (
                    <Text margin="0" key={index}>
                      {item.texte}
                    </Text>
                  ))}
                </Card>
              </Item>
            </Row>
          </Column>
        </ItemLeft>

        {/* Sidebar */}
        <ItemRight>
          <Column>
            <Row>
              <Item flex="0 0 100%">
                <Card color="greyLighter" padding="30px 23px">
                  <TextInfo color="primary">{title}</TextInfo>
                </Card>
              </Item>
              <Item flex="0 0 50%">
                <Card color="greyLighter" padding="15px 0">
                  <TextInfo color="primary">{contract}</TextInfo>
                </Card>
              </Item>
              <Item flex="0 0 50%">
                <Card color="greyLighter" padding="15px 0">
                  <Row align="center">
                    <Icon src={IconLieu} alt="ville" />
                    <TextInfo>{city}</TextInfo>
                  </Row>
                </Card>
              </Item>
              <Item flex="0 0 100%">
                <Card color="greyLighter" padding="15px 0">
                  <TextMedium>{experience}</TextMedium>
                </Card>
              </Item>
            </Row>
            <WrapperButton>
              <ButtonAnchor
                anchor="postuler"
                offset={globalVariables.navbarHeight}
                title="Postuler"
                colors="primaryWithBg"
              />
            </WrapperButton>
            <SocialList>
              <SocialLink
                href="https://www.facebook.com/slapdigital/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img fluid={data.fbk.childImageSharp.fluid} />
              </SocialLink>
              <SocialLink
                href="https://twitter.com/slapdigital"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img fluid={data.twt.childImageSharp.fluid} />
              </SocialLink>
              <SocialLink
                href="https://www.linkedin.com/company/slap-digital"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img fluid={data.lkd.childImageSharp.fluid} />
              </SocialLink>
            </SocialList>
            <Card shadow padding="30px 25px">
              <Row justify="space-between">
                <Item>
                  <Text margin="0 0 19px 0" align="left">
                    Année de création
                  </Text>
                </Item>
                <Item>
                  <Text
                    margin="0 0 19px 0"
                    color="primary"
                    weight="700"
                    align="right"
                  >
                    2012
                  </Text>
                </Item>
              </Row>
              <Row justify="space-between">
                <Item>
                  <Text margin="0 0 19px 0" align="left">
                    Moyenne d'âge
                  </Text>
                </Item>
                <Item>
                  <Text
                    color="primary"
                    weight="700"
                    margin="0 0 19px 0"
                    align="right"
                  >
                    {age} ans
                  </Text>
                </Item>
              </Row>
              <Row justify="space-between">
                <Item>
                  <Text margin="0 0 19px 0" align="left">
                    Collaborateurs
                  </Text>
                </Item>
                <Item>
                  <Text
                    margin="0 0 19px 0"
                    color="primary"
                    weight="700"
                    align="right"
                  >
                    {collaborateurs}
                  </Text>
                </Item>
              </Row>
            </Card>
          </Column>
        </ItemRight>
      </Row>
    </Section>
  )
}

export default FicheDePostContent
