import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FicheDePostContent from "../components/FicheDePoste"
import Postuler from "../components/FicheDePoste/postuler"
import Offres from "../components/Recrutement/offres"
import Candidature from "../components/Recrutement/candidature"
import BannerV2 from "../components/elements/BannersV2/BannerV2"

const anchors = [
  {
    content: "La mission",
    anchor: "mission",
  },
  {
    content: "Profil recherché",
    anchor: "profil-recherche",
  },
]

const FicheDePoste = ({ data, location }) => {
  const { fiche_de_poste, jobs, recrutement } = data
  const bannerLeftCardContent = {
    mainContent: fiche_de_poste.acf.texte,
    titleFirstLine: fiche_de_poste.acf.titre_1,
    titleSecondLine: fiche_de_poste.acf.titre_2,
    subTitle: fiche_de_poste.acf.sous_titre,
  }
  return (
    <Layout
      location="/recrutement"
      locationBis={location.pathname}
      crumbLabel="On recrute"
      crumbLabelBis={fiche_de_poste.acf.titre_banniere}
      locationGTM={location.href}
    >
      <SEO
        title={fiche_de_poste.acf.title}
        description={fiche_de_poste.acf.meta_description}
        lang="fr"
        url={location.pathname}
      />
      <BannerV2
        anchors={anchors}
        hasApplyButton
        hasBreadCrumb
        isFicheDePostePage
        bannerLeftCardContent={bannerLeftCardContent}
      />
      <FicheDePostContent
        title={fiche_de_poste.acf.titre_banniere}
        jobTitle={fiche_de_poste.acf.titre_job}
        jobText={fiche_de_poste.acf.description_job}
        contract={fiche_de_poste.acf.contrat_banniere}
        city={fiche_de_poste.acf.ville_banniere}
        age={fiche_de_poste.acf.moyenne_dage}
        collaborateurs={fiche_de_poste.acf.nombre_de_collaborateurs}
        experience={fiche_de_poste.acf.experience_bloc}
        teamLeader={fiche_de_poste.acf.nom_team_leader}
        imageTeamLeader={
          fiche_de_poste.acf.image_team_leader.localFile.childImageSharp.fluid
            .src
        }
        imageEquipe={
          fiche_de_poste.acf.image_equipe.localFile.childImageSharp.fluid.src
        }
        imageBureaux={
          fiche_de_poste.acf.image_bureau.localFile.childImageSharp.fluid.src
        }
        avantages={fiche_de_poste.acf.avantages}
        profils={fiche_de_poste.acf.profils}
        case12={fiche_de_poste.acf.case_8.localFile.childImageSharp.fluid.src}
        case8={fiche_de_poste.acf.case_12.localFile.childImageSharp.fluid.src}
        case5={fiche_de_poste.acf.case_5.localFile.childImageSharp.fluid.src}
        case1={fiche_de_poste.acf.case_1}
        case2={fiche_de_poste.acf.case_2}
        case3={fiche_de_poste.acf.case_3}
        case4={fiche_de_poste.acf.case_4}
        case6={fiche_de_poste.acf.case_6}
        case7={fiche_de_poste.acf.case_7}
        case9={fiche_de_poste.acf.case_9}
        case10={fiche_de_poste.acf.case_10}
        case11={fiche_de_poste.acf.case_11}
        case13={fiche_de_poste.acf.case_13}
        case14={fiche_de_poste.acf.case_14}
        profilCase8={fiche_de_poste.acf.profil_case_8}
      />
      <Postuler titleJob={fiche_de_poste.acf.titre_banniere} />
      <Offres
        title="Nos offres similaires"
        items={jobs.edges.filter(
          item => item.node.path !== fiche_de_poste.path
        )}
      />
      <Candidature
        title={recrutement.acf.titre_candidature}
        text={recrutement.acf.texte_candidature}
      />
    </Layout>
  )
}

export default FicheDePoste

export const query = graphql`
  query($id: String!) {
    recrutement: wordpressPage(
      id: { eq: "bea2f876-6b5c-558d-8d4e-617505bf9811" }
    ) {
      acf {
        titre_candidature
        texte_candidature
      }
    }
    jobs: allWordpressWpFichesDePoste {
      edges {
        node {
          path
          acf {
            contrat_banniere
            titre_banniere
            image_banniere {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            statut_de_publication
          }
        }
      }
    }
    fiche_de_poste: wordpressWpFichesDePoste(id: { eq: $id }) {
      path
      acf {
        title
        meta_description
        titre_banniere
        texte_banniere
        image_banniere {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 3000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        titre_1
        titre_2
        sous_titre
        texte
        contrat_banniere
        ville_banniere
        experience_bloc
        titre_job
        description_job
        nom_team_leader
        moyenne_dage
        nombre_de_collaborateurs
        avantages {
          texte
        }
        case_1 {
          texte_case_1
          titre_case_1
        }
        case_2 {
          texte_case_2
          titre_case_2
        }
        case_3 {
          texte_case_3
          titre_case_3
        }
        case_4 {
          texte_case_4
          titre_case_4
        }
        case_5 {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
        case_6 {
          titre_case_6
          texte_case_6
        }
        case_7 {
          texte_case_7
          titre_case_7
        }
        case_8 {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
        case_9 {
          texte_case_9
          titre_case_9
        }
        case_10 {
          texte_case_10
          titre_case_10
        }
        case_11 {
          texte_case_11
          titre_case_11
        }
        case_12 {
          localFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
        case_13 {
          texte_case_13
          titre_case_13
        }
        case_14 {
          titre_case_14
          texte_case_14
        }

        image_bureau {
          localFile {
            url
            childImageSharp {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
        image_equipe {
          localFile {
            url
            childImageSharp {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
        image_team_leader {
          localFile {
            url
            childImageSharp {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
        profil_case_8 {
          texte
        }
        profils {
          profil {
            titre_bleu_1
            texte_bleu_2
            texte
            images {
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
