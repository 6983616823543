import React, { useState } from "react"
import styled from "styled-components"

import Section from "../elements/Section"
import Container from "../elements/Container"
import Title from "../elements/Title"
import globalVariables from "../globalVariables"
import Dropdown2 from "../elements/Dropdown/index2"
import Fleche from "../../images/icons/fleche-grise.svg"
import CandidatureForm from "../Recrutement/form"
import Anchor from "../elements/Anchor"

const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin: 60px 0 0 0;
  .dropdown-enter-done {
    height: auto;
    border: solid 1px #aeaeae;
    top: 3.5rem;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`

const Select = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  cursor: pointer;
  /* font-size: 14px; */
  background: white;
  height: 55px;
  padding: 0 10px 0 25px;
  border: solid 1px #aeaeae;

  img {
    transition: all 0.2s ease-in-out;
    transform: ${props => (props.isOpen ? "rotate(90deg)" : "rotate(270deg)")};
  }
  @media (min-width: ${globalVariables.minTablet}) {
    min-width: 350px;
  }
`

const Arrow = styled.img`
  margin-left: 30px;
  width: 8px;
`

const WrapperAnchor = styled.div`
  @media (max-width: ${globalVariables.maxMobile}) {
    display: none;
  }
  position: absolute;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);
`

const Postuler = ({ titleJob }) => {
  const [job, setJob] = useState(titleJob)

  const handleClickJob = (value, callback) => {
    setJob(value)
    callback()
  }

  return (
    <Section color="primary" border id="postuler">
      <WrapperAnchor>
        <Anchor anchor="postuler" offset={globalVariables.navbarHeight} />
      </WrapperAnchor>
      <Container>
        <Title className="title1" color="white" position="center">
          Postuler
        </Title>
        <Row>
          <Dropdown2
            top="2.5rem"
            left="-20px"
            triggerComponent={({ handleClick, isOpen }) => {
              return (
                <Select onClick={handleClick} isOpen={isOpen}>
                  {job === "Tous" ? "Tous les postes" : job}
                  <Arrow src={Fleche} alt="choisir un poste" />
                </Select>
              )
            }}
            items={[titleJob]}
            value={job}
            handleClickSelect={handleClickJob}
          />
        </Row>
        <CandidatureForm job={job} title="postuler" />
      </Container>
    </Section>
  )
}

export default Postuler
